@import './partials/authorization';
.auth__page {
  padding: 100px 0;
  position: relative;
  .container {
    position: relative;
    z-index: 2;
  }
}

@media (max-width: 576px) {
  .auth__page {
    padding: 50px 0;
  }
}