.authorization {
  display: flex;
  align-items: flex-start;
  &__container {
    width: 100%;
    padding-right: 25px;
  }
  &__logo {
    display: block;
    width: 200px;
    padding-bottom: 75px;
    &-image {
      width: 100%;
      height: auto;
    }
  }
  &__title {
    font-size: 22px;
    display: block;
    padding-bottom: 25px;
  }
  &__form {
    display: inline-block;
    &-input {
      width: 220px;
    }
    &-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 25px;
    }
    &-label {
      padding-right: 15px;
      span {
        color: #282828;
        display: block;
      }
      a {
        display: block;
        color: #4e7cb3;
        &:hover {
          color: #32619a;
          text-decoration: underline;
        }
      }
    }
    &-submit {
      width: 220px;
      display: block;
      margin: 0 0 0 auto;
    }
  }
  &__text {
    color: #6d6d6d;
    font-size: 12px;
    display: block;
    max-width: 360px;
    padding-top: 40px;
  }
}

@media all and(max-width: 992px) {
  .authorization {
    height: auto;
    padding: 0;
    .container {
      padding: 0 25px;
    }
  }
}
@media all and(max-width: 768px) {
  .authorization {
    .banner {
      width: 240px;
      &__content {
        padding: 20px;
      }
      &__title {
        font-size: 20px;
        padding-bottom: 20px;
      }
    }
    &__form-group {
      display: block;
    }
    &__form {
      display: block;
    }
    &__form-label {
      display: flex;
      padding: 0 0 5px 0;
      span {
        padding-right: 10px;
      }
    }
    &__form-input,
    &__form-submit {
      width: 100%;
    }
  }
}

@media all and(max-width: 576px) {
  .authorization {
    display: block;
    &__text {
      max-width: 100%;
    }
    &__container {
      padding: 0;
    }
    &__logo {
      padding-bottom: 40px;
    }
    .banner {
      width: 100%;
      margin-top: 45px;
    }
  }
}